import styles from "./MultiChoice.module.css";
import React from "react";

function MultiChoice({ text, options }) {
  return (
    <div className={styles.container}>
      <div>{text}</div>
      <div className={styles.options}>
        {options.map((option) => {
          return (
            <button
              style={{margin:"5px"}}
              key={option?.title + option?.id}
              disabled={option?.disabled}
              onClick={
                option?.onClick
                  ? option.onClick
                  : () => {
                      // console.log("No function got in onclick");
                    }
              }
            >
              {option?.title || ""}
            </button>
          );
        })}
      </div>
    </div>
  );
}
export default MultiChoice;
