import { getUUID } from "./uuid"

export const messageProcessor = (messagePayload) => {
  const randomId = getUUID();
  return {
    id: randomId,
    sessionId: "",
    identifier: "",
    tenantId: "",
    teamName: "",
    teamId: "",
    flowId: "",
    customer: "",
    customerId: localStorage.getItem('userId') || "",
    // agent: "",
    // meta: "",
    timeStamp: new Date(),
    // userId: localStorage.getItem('userId') || "",
    replyTo: "",
    plateform: 'webchat',
    message: {
      from: 'customer',
      timeStamp: new Date(),
      ...messagePayload,
      id: randomId,
    }
  }
}

export const getUsefulData = (messagePayload) => {
  // return type: 'component' | 'text'
  // if 'text' return messageText
  // if 'component' return Components, props
  // switch (messagePayload.message.type) {
  //   case 'text':
  //     return { type: 'text', text: messagePayload.message.content.text }
  //   case 'image':
  //     return { type: 'component', Component: Image, props: { src: messagePayload.message.content.link, alt: "Image not loaded." } }
  //   case 'video':
  //     return { type: 'component', }
  //   case 'document':
  //     return <DocumentCustom message={message} />
  //   case 'audio':
  //     return <AudioTemp message={message} />
  //   case 'contacts':
  //     return <Contact message={message} />;

  //   default:
  //     break;
  // }
  // return (
  //   <div>MessageRenderer</div>
  // )
}