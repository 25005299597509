import React from 'react'
import './DocumentCustom.css';

function formatBytes(bytes) {
  const units = ["B", "KB", "MB", "GB"];
  let index = 0;
  while (bytes >= 1024 && index < units.length - 1) {
    bytes /= 1024;
    index++;
  }
  return bytes.toFixed(2) + " " + units[index];
}

function DocumentCustom({ docLink, docType, fileName, fileSize }) {
  function downloadPDF(url) {
    if (!url)
      return;

    if (url instanceof Blob) {
      const a = document.createElement("a");
      a.href = url;
      a.style.display = "none";
      a.target = "_blank";
      a.download = (fileName) || "downloaded_pdf.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style.display = "none";
          a.download = (fileName) || "downloaded_pdf.pdf"; // Set the desired filename
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => console.log("Download error:", error));
    }
  }

  return (
    <div className="msg-doc_main">
      <div className="msg-doc_content">
        <div className="msg-doc_name">{fileName || docLink.split("/").pop()}</div>
        <div className="msg-doc_size">
          <span>{formatBytes(fileSize) || "null"}</span>
          <span className="">{docType && docType.split("/").at(-1)}</span></div>
      </div>
      <div className="msg-doc_download_button" onClick={() => downloadPDF(docLink)} >
        <img src="/images/Group.svg" />
      </div>
    </div>
  )
}

export default DocumentCustom;